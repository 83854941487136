<template>

	<!-- Project Card -->
	<a-card class="card-project">
		<img
			slot="cover"
			alt="example"
            class="image-container"
			:src="image"
		/>
		<div class="card-tag">Artículo</div>
		<h5>{{ title }}</h5>
		<p>
			{{ description | shortenString }}
		</p>
        <h4>RD ${{price}}.00</h4>
		<a-row type="flex" :gutter="6" class="card-footer" align="middle">
			<a-col :span="12">
				<a-button size="small">DISPONIBLE</a-button>
			</a-col>
		</a-row>
	</a-card>
	<!-- / Project Card -->

</template>

<script>

	export default ({
		props: {
			id: {
				type: String,
				required: true,
			},
			title: {
				type: String,
				default: "",
			},
            price: {
				type: String,
				default: 0,
			},
			description: {
				type: String,
				default: "",
			},
			image: {
				type: String,
				default: "",
			},
			
		},
        filters: {
          shortenString(value) {
            if (value.length > 80) {
                return value.slice(0, 80) + '...';
            }
            return value;
		  },
		},
		data() {
			return {
			}
		},
	})
</script>
<style lang="scss">
    .image-container{
        height: 250px;
        object-fit: cover;
    }
</style>