import { db } from '@/firebase/firebaseDB'
const category = db.collection('category');


export const getCategory = (callback) => {
    return category.onSnapshot((snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      callback(list);
    });
  };
  