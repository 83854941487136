import { db } from '@/firebase/firebaseDB'
const products = db.collection('products');

export const getArticles = (callback) => {  
    return products.onSnapshot((snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      callback(list);
    });
  };
export const getArticleByCategoryId = (callback,id) => {  
    return products.where('category', "==", id).onSnapshot((snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      callback(list);
    });
}; 

export const getArticleById = (callback,id) => {  
    return products.doc(`${id}`).onSnapshot((snapshot) => {
      const list = snapshot.data()
  
      callback(list);
    });
 
}; 
  

export const createProduct = async (data) => {
  data.id =  products.doc().id;
  products.doc(data.id).set(data)
}
  