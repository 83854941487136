
<template>
	<div>
		<a-breadcrumb class="mb-10">
			<a-breadcrumb-item><router-link to="/"> Inicio</router-link></a-breadcrumb-item>
			<a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
		</a-breadcrumb>
	<a-card :bordered="false" class="header-solid h-full mb-24 card-container" :bodyStyle="{paddingTop: '14px'}">
	<div class="card-title">
			<h6>Buscar producto</h6>
			<p>Filtrar por tipo</span></p>
		</div>
	<a-row>
		<a-row>
			<a-col :md="4" :xl="4">
				<a-select  placeholder="Categoria" style="width: 220px" class="mb-10 " @change="handleChange">
					<a-select-option  v-for="(item, index) in category" :key="index" :value="item.key" >
						{{item.value}}
					</a-select-option>
				</a-select>
			</a-col>
		</a-row>
	</a-row>
	</a-card>
	<a-row type="flex" :gutter="[24,24]" align="stretch">
		<a-col :span="24" :md="12" :xl="6" v-for="(item, index) in products" :key="index">
	
			<router-link :to="`/detail/${item.id}`" class="btn-sign-in">
			<a-card :bordered="false" class="header-solid h-full mb-24 card-container" :bodyStyle="{paddingTop: '14px'}">
			
				<CardProduct
					:id="item.id"
					:title="item.name"
					:description="item.description"
					:image="item.images[0]"
					:price="item.price"
				></CardProduct>
			</a-card>
			</router-link>
		</a-col>
		</a-row>
		
	</div>
</template>

<script>

import CardBarChart from '@/components/Cards/CardBarChart' ;
import CardProduct from "@/components/Cards/CardProduct";
import { getArticles,getArticleByCategoryId } from '@/services/products/products';
import { getCategory } from '@/services/category/category';

	const products = [];
	const category=[];

	export default ({
		components: {
			CardProduct
		},
		data() {
			return {

				products,
				category
				
			}
		},
		methods:{
			async handleChange(value){
				await getArticleByCategoryId((data) => {
					this.products=data
	    	    },value)	
			},
			async getCategory(){
				let list =  await getCategory((list) => {
					this.category=list
	    		})	
			}
		},
		async created(){
		this.getCategory()
		 let list =  await getArticles((list) => {
			this.products=list
	    	})
			
		}
	})

</script>

<style lang="scss">

</style>